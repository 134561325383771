/**
 * Stactize.Signup.BFF
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SubscriptionState = 'Provisioning' | 'Provisioned' | 'ProvisioningFailed' | 'Suspended' | 'Active' | 'Deleted' | 'Unsubmitted';

export const SubscriptionState = {
    Provisioning: 'Provisioning' as SubscriptionState,
    Provisioned: 'Provisioned' as SubscriptionState,
    ProvisioningFailed: 'ProvisioningFailed' as SubscriptionState,
    Suspended: 'Suspended' as SubscriptionState,
    Active: 'Active' as SubscriptionState,
    Deleted: 'Deleted' as SubscriptionState,
    Unsubmitted: 'Unsubmitted' as SubscriptionState
};

