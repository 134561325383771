/**
 * Stactize.Signup.BFF
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ConfigurableFont = 'Raleway' | 'OpenSans' | 'SourceSansPro' | 'Roboto' | 'RobotoCondensed' | 'Arial';

export const ConfigurableFont = {
    Raleway: 'Raleway' as ConfigurableFont,
    OpenSans: 'OpenSans' as ConfigurableFont,
    SourceSansPro: 'SourceSansPro' as ConfigurableFont,
    Roboto: 'Roboto' as ConfigurableFont,
    RobotoCondensed: 'RobotoCondensed' as ConfigurableFont,
    Arial: 'Arial' as ConfigurableFont
};

