/**
 * Stactize.Signup.BFF
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PublisherState = 'Active' | 'Suspended' | 'Deleted';

export const PublisherState = {
    Active: 'Active' as PublisherState,
    Suspended: 'Suspended' as PublisherState,
    Deleted: 'Deleted' as PublisherState
};

