/**
 * Stactize.Signup.BFF
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type UserFieldType = 'String' | 'Number' | 'Date' | 'Checkbox' | 'Country' | 'OrganisationSize' | 'Telephone' | 'Timezone';

export const UserFieldType = {
    String: 'String' as UserFieldType,
    Number: 'Number' as UserFieldType,
    Date: 'Date' as UserFieldType,
    Checkbox: 'Checkbox' as UserFieldType,
    Country: 'Country' as UserFieldType,
    OrganisationSize: 'OrganisationSize' as UserFieldType,
    Telephone: 'Telephone' as UserFieldType,
    Timezone: 'Timezone' as UserFieldType
};

